<template>
  <div class="account">
    <div class="cover"></div>
    <div class="container">
      <div class="row">
        <div class="d-none d-lg-block col-lg-6 align-self-center">
          <h1 class="info">Reise, so wie du bist.</h1>
        </div>
        <div class="col-12 col-sm-8 offset-sm-2 col-md-6 offset-md-3 col-lg-4 offset-lg-2">
          <div class="card">
            <h4 class="card-title">Anmelden</h4>
            <div class="login-container form">
              <div class="form-group">
                <span class="material-icons light left">person</span>
                <input type="text" class="form-control icon-left lg" @blur="validateEmailInput" v-model="credentials.email" placeholder="E-Mail"/>
              </div>
              <span class="validation-error" v-show="showEmailError">Geben Sie Ihre E-Mail Adresse an.</span>
            </div>
            <div class="login-container">
              <div class="form-group">
                <span class="material-icons light left">lock</span>
                <input type="password" class="form-control icon-left lg"  @blur="validatePasswordInput" v-model="credentials.password" placeholder="Passwort"/>
              </div>
              <span class="validation-error" v-show="showPasswordError">Geben Sie Ihr Passwort ein. Das Passwort ist mindestens 8 Zeichen lang und enthält mindestens eine Zahl.</span>
            </div>
            <router-link class="btn btn-link pw-btn" to="/apply-for-new-password">Passwort vergessen?</router-link>
            <button @click="dispatchLogin" class="btn btn-block">Anmelden</button>
          </div>
          <div class="card mt40">
            <p class="mb20">Noch nicht dabei?</p>
            <h6 class="mb-1">Werde auch du ein Tripmeister!</h6>
            <router-link class="btn btn-block btn-active mt-3" to="/signup">Jetzt Profil erstellen</router-link>

            <h6 class="mb-1 mt50">Sie sind ein*e Anbieter*in?</h6>
            <a href="https://cms.tripmeister.de/" target="_blank">Jetzt kostenlos registrieren & inserieren!</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Footer Margin -->
    <div class="footer-margin"></div>
  </div>
</template>

<script>
  import {verify, csrf } from '@/api/auth';
  import { validEmail, validatePassword } from '@/utils/validate';
  import { showLoader, hideLoader } from '@/utils/helpers';

  export default {
    name: 'login',
    components: {
      TheModal: () => import('@/components/modal/show'),
    },
    data() {
      return {
       credentials: {
        email: "",
        password: "",
      },
      showEmailError: false,
      showPasswordError: false,
      afterloginModal: false, 
      isFirstRegistrationEver: false,
      loader: false,
    };
  },
  beforeCreate() {
    /*Wenn es um eine verifizierung geht*/
    if(this.$route.params.token){
      verify(this.$route.params.token).then( response => {
        this.$notify({
          duration: 7000,
          title: 'E-Mail Adresse verifiziert!',
          text: response
        });
      }).catch(() => {
        this.$notify({
          duration: 5000,
          title: 'Verifizierung fehlgeschlagen!',
          text: 'Die Verifizierung Ihrer E-Mail Adresse hat nicht funktioniert. Versuchen Sie es später nocheinmal oder kontaktieren Sie uns, wenn Sie sich nicht einloggen können.'
        });
      });
    }
  },
  mounted(){
    if(this.$route.query.afterRegistration){
      this.afterloginModal = true;
    }
  },
  computed:{
    token(){
      return this.$store.getters.getToken;
    },
  },
  methods: {
    verify,
    showLoader,
    hideLoader,
    validEmail,
    validatePassword,
    validatePasswordInput(){
      if(!this.validatePassword(this.credentials.password)){
        this.showPasswordError=true;
        return false;
      }else{
        this.showPasswordError=false;
        return true;
      }
    },
    validateEmailInput(){
      if(!validEmail(this.credentials.email)){
        this.showEmailError=true;
        return false;
      }
      else{
        this.showEmailError=false;
        return true;
      }
    },
    dispatchLogin(){
      var emailValid = this.validateEmailInput();
      var passwordValid = this.validatePasswordInput();
      if(!emailValid || !passwordValid){
        return;
      }

      this.loader = this.showLoader(this.loader);

      csrf().then(() => {
        this.$store.dispatch('login', this.credentials).then(() => {
          this.$notify({
            duration: 2500,
            title: 'Erfolgreich angemeldet!',
            text: 'Sie sind im Tripmeister angemeldet.'
          });

          /*load the Collections for the user*/
          this.$store.dispatch('loadUserCollections');
          this.$router.push({name:'Home'});

        }).catch(error => {
          this.$notify({
            duration: 4500,
            type: 'error',
            title: 'Fehler bei der Anmeldung!',
            text: error.data
          });
        }).finally(()=>{
          this.loader = this.hideLoader(this.loader);
        });
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  @import '@/scss/_variables.scss';
  .validation-error{
    color:#E5321B;
    font-size: 13px;
    text-align: center;
  }

  .form-group {
    margin-bottom: 0px;
  }

  .login-container {
    margin-bottom: 1rem;
  }

  .pw-btn {
    margin-top: 0 !important;
  }
</style>